
import Giva92 from '../../Giva/Tipe 9/Tipe 9 (1).webp'
import Giva93 from '../../Giva/Tipe 9/Tipe 9 (3).webp'
import Giva94 from '../../Giva/Tipe 9/Tipe 9 (4).webp'
import Giva95 from '../../Giva/Tipe 9/Tipe 9 (5).webp'
import Giva96 from '../../Giva/Tipe 9/Tipe 9 (6).webp'
import Giva97 from '../../Giva/Tipe 9/Tipe 9 (7).webp'
import Giva98 from '../../Giva/Tipe 9/Tipe 9 (8).webp'
import Giva99 from '../../Giva/Tipe 9/Tipe 9 (9).webp'
import Giva910 from '../../Giva/Tipe 9/Tipe 9 (10).webp'
import Giva911 from '../../Giva/Tipe 9/Tipe 9 (11).webp'
import Giva912 from '../../Giva/Tipe 9/Tipe 9 (12).webp'
import Giva913 from '../../Giva/Tipe 9/Tipe 9 (13).webp'
import Giva914 from '../../Giva/Tipe 9/Tipe 9 (14).webp'
import Giva915 from '../../Giva/Tipe 9/Tipe 9 (15).webp'

const Giva9images =[
    Giva92,Giva93,Giva94,Giva95,Giva96,Giva97,Giva98,Giva99,Giva910,Giva911,Giva912,Giva913,Giva914,Giva915
]

export default Giva9images;