import Giva81 from '../../Giva/Tipe 8/Tipe 8 (1).webp'
import Giva82 from '../../Giva/Tipe 8/Tipe 8 (2).webp'
import Giva83 from '../../Giva/Tipe 8/Tipe 8 (3).webp'
import Giva84 from '../../Giva/Tipe 8/Tipe 8 (4).webp'
import Giva85 from '../../Giva/Tipe 8/Tipe 8 (5).webp'
import Giva86 from '../../Giva/Tipe 8/Tipe 8 (6).webp'
import Giva87 from '../../Giva/Tipe 8/Tipe 8 (7).webp'
import Giva88 from '../../Giva/Tipe 8/Tipe 8 (8).webp'
import Giva89 from '../../Giva/Tipe 8/Tipe 8 (9).webp'
import Giva810 from '../../Giva/Tipe 8/Tipe 8 (10).webp'
import Giva811 from '../../Giva/Tipe 8/Tipe 8 (11).webp'
import Giva812 from '../../Giva/Tipe 8/Tipe 8 (12).webp'
import Giva813 from '../../Giva/Tipe 8/Tipe 8 (13).webp'
import Giva814 from '../../Giva/Tipe 8/Tipe 8 (14).webp'
import Giva815 from '../../Giva/Tipe 8/Tipe 8 (15).webp'

const Giva8images =[
    Giva81,Giva82,Giva83,Giva84,Giva85,Giva86,Giva87,Giva88,Giva89,Giva810,Giva811,Giva812,Giva813,Giva814,Giva815
]

export default Giva8images;