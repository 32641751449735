import React from "react";
import "./promo.scss";
import penawaran from "../../media/Hardsell GW.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20rumah%20ini%20(Promo)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  return (
    <div className="judulpromo">
      <div id="promo" className="judul">
        <h1>PROMO TERBARU</h1>
      </div>
      <div id="promo" className=" containerPenawaran">
        <div className="containercontent">
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free PPN</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Biaya KPR</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Furnished</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Disc Special KPR DP Cicil 2%*
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Disc Special Cash Bertahap 5%*
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Disc Special Cash Keras 10%*
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Disc Early Birth 10 Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Disc Lucky Dip 10 Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Full Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smart Door Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free CCTV</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Solar Panel</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Lift</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;KPR 100% Approved
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Get The Offer Now
          </button>
        </div>
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
