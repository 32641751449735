
import Giva102 from '../../Giva/Tipe 10/Tipe 10 (1).webp'
import Giva103 from '../../Giva/Tipe 10/Tipe 10 (3).webp'
import Giva104 from '../../Giva/Tipe 10/Tipe 10 (4).webp'
import Giva105 from '../../Giva/Tipe 10/Tipe 10 (5).webp'
import Giva106 from '../../Giva/Tipe 10/Tipe 10 (6).webp'
import Giva107 from '../../Giva/Tipe 10/Tipe 10 (7).webp'
import Giva108 from '../../Giva/Tipe 10/Tipe 10 (8).webp'
import Giva109 from '../../Giva/Tipe 10/Tipe 10 (9).webp'
import Giva1010 from '../../Giva/Tipe 10/Tipe 10 (10).webp'
import Giva1011 from '../../Giva/Tipe 10/Tipe 10 (11).webp'
import Giva1012 from '../../Giva/Tipe 10/Tipe 10 (12).webp'
import Giva1013 from '../../Giva/Tipe 10/Tipe 10 (13).webp'
import Giva1014 from '../../Giva/Tipe 10/Tipe 10 (14).webp'
import Giva1015 from '../../Giva/Tipe 10/Tipe 10 (15).webp'
import Giva1016 from '../../Giva/Tipe 10/Tipe 10 (16).webp'

const Giva10images =[
    Giva102,Giva103,Giva104,Giva105,Giva106,Giva107,Giva108,Giva109,Giva1010,Giva1011,Giva1012,Giva1013,Giva1014,Giva1015,Giva1016
]

export default Giva10images;