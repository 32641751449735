import React from "react";
import "./ruko1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Sinsaimages from "./Sinsa.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLayerGroup,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const ruko1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Sapphirewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Dhevia%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20ruko%20ini%20(Wesfield)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard5">
        <div className="card">
          <Slider {...settings}>
            {Sinsaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Westfield</div>
            <div className="gridmap">
              <div className="namalokasi">
                Supreme Location, Concept, Access
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">11</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 50m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 106m²</span>
            <FontAwesomeIcon color="white" icon={faLayerGroup} />
            <span> 3</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Sapphirewa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default ruko1;
