import React from "react";
import "./Giva1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Giva8images from "../../../media/GW/Giva/Tipe 8/Giva8";
import Giva9images from "../../../media/GW/Giva/Tipe 9/Giva9";
import Giva10images from "../../../media/GW/Giva/Tipe 10/Giva10";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Giva8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Giva8)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Giva9 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Giva9)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Giva10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281574115424&text=Halo+Radi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Giva10)%20https://www.marketing-grandwisata.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard88">
        <div className="card">
          <Slider {...settings}>
            {Giva8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Giva 8</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 17 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 144m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 185m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span>4+1</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span>4+1</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Giva8} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Giva9images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Giva 9</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 21 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 180m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 195m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 4+1</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 4+1</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Giva9} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Giva10images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="nama-rumah">Giva 10</div>
            <div className="gridmap">
              <div className="namalokasi">
                Tanpa DP, Cicilan Start 26 Juta/Bulan
              </div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="gridspek">
                <FontAwesomeIcon
                  color="#EEAE5B"
                  icon={faArrowsUpDownLeftRight}
                />
                <span> 220m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faHouse} />
                <span> 260m²</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faBed} />
                <span> 5+1</span>
                <FontAwesomeIcon color="#EEAE5B" icon={faShower} />
                <span> 4+1</span>
              </div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Giva10} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
